import React from 'react';

const NoMatch = () => {
    return (
    <div>
      Ruh Roh
    </div>
    )
};

export default NoMatch;
